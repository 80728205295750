<script setup lang="ts">
import { useMediaQuery, useOnline } from "@vueuse/core";
import { useDark } from "@vueuse/core";
import { ElConfigProvider, ElNotification } from "element-plus";
import type { ComponentPublicInstance } from "vue";
import { useRoute } from "vue-router";

import { useCompanyById } from "@/api/company";
import { useTitleWithTemplate } from "@/composables/title";
import { defineAbilityFor } from "@/services/ability";
import { useAuthStore } from "@/stores/auth";
import { useCompanyStore } from "@/stores/company";
import type { Company } from "@/types/company";

import GlobalError from "./components/GlobalError.vue";
import { UserRole } from "./types/user";
import { DateTime } from "./utils/datetime";

useDark();

const isOnline = useOnline();

useTitleWithTemplate();

const authStore = useAuthStore();
const user = toRef(authStore, "user");
watch(
  user,
  () => {
    if (user.value) {
      defineAbilityFor(user.value);
    }
  },
  { immediate: true }
);

watch(isOnline, () => {
  if (!isOnline.value) {
    ElNotification({
      title: "Connection",
      message: "You are offline",
      type: "warning",
      duration: 2000,
    });
  } else {
    ElNotification({
      title: "Connection",
      message: "Connection restored",
      type: "success",
      duration: 2000,
    });
  }
});

const companyStore = useCompanyStore();
const route = useRoute();

const companyId = computed(() => +route.params.companyId);

const { data: company } = useCompanyById(companyId);

watch(
  companyId,
  () => {
    companyStore.currentCompanyId = companyId.value;
  },
  { immediate: true }
);

watch(
  company,
  (value: Company | null | undefined) => {
    if (value) {
      companyStore.currentCompany = value;
    }
  },
  { immediate: true }
);

watch(
  () => authStore.isLoggedIn,
  () => {
    if (
      authStore.isLoggedIn &&
      authStore.user?.role === UserRole.Companyadmin
    ) {
      companyStore.fetchCompany();
    }
  },
  { immediate: true }
);

watch(
  () => companyStore.timezoneString,
  () => {
    if (companyStore.timezoneString) {
      DateTime.setTimeZone(companyStore.timezoneString);
    }
  },
  { immediate: true }
);

const isLargeScreen = useMediaQuery("(min-width: 1920px)");
const configSize = computed(() => (isLargeScreen.value ? "large" : "default"));

// const showGlobalError = ref(false);
//
// onErrorCaptured(
//   (err: unknown, instance: ComponentPublicInstance | null, info: string) => {
//     setTimeout(() => {
//       showGlobalError.value = true;
//     }, 0);
//   }
// );
</script>

<template>
  <el-config-provider :size="configSize">
    <router-view />
    <!--    <GlobalError v-if="showGlobalError" />-->
  </el-config-provider>
</template>

<style lang="scss">
@import "@/assets/base.scss";

.app-layout {
  background-color: var(--el-bg-color-page);
  min-height: 100vh;
  max-height: 100vh;

  &__main {
    padding: 20px 24px 0 24px;
    display: flex;
    flex-direction: column;
  }
}
</style>
