import type { RouteRecordRaw } from "vue-router";

import { ROUTE_BASE_NAME, ROUTE_NAMES } from "./config";

export const routes: RouteRecordRaw[] = [
  {
    path: "drivers",
    name: ROUTE_BASE_NAME,
    component: () => import("@/modules/drivers/views/DriversMain.vue"),
    redirect: { name: ROUTE_NAMES.List },
    meta: {
      breadcrumb: "Drivers",
    },
    children: [
      {
        path: "",
        name: ROUTE_NAMES.List,
        component: () => import("@/modules/drivers/views/DriversList.vue"),
      },
      {
        path: "create",
        name: ROUTE_NAMES.Create,
        component: () => import("@/modules/drivers/views/DriversCreate.vue"),
        meta: {
          breadcrumb: "New driver",
        },
      },
      {
        path: "edit/:id",
        name: ROUTE_NAMES.Update,
        component: () => import("@/modules/drivers/views/DriversUpdate.vue"),
        meta: {
          breadcrumb: "Update driver",
        },
      },
    ],
  },
];
