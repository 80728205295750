import type { RouteRecordRaw } from "vue-router";

import { ROUTE_BASE_NAME, ROUTE_NAMES } from "./config";

export const routes: RouteRecordRaw[] = [
  {
    path: "vehicles",
    name: ROUTE_BASE_NAME,
    component: () => import("./views/VehiclesMain.vue"),
    redirect: { name: "vehicles.list" },
    meta: {
      breadcrumb: "Vehicles",
    },
    children: [
      {
        path: "",
        name: ROUTE_NAMES.VehiclesList,
        component: () => import("./views/VehiclesList.vue"),
      },
      {
        path: "create",
        name: ROUTE_NAMES.VehiclesCreate,
        component: () => import("./views/VehiclesCreate.vue"),
        meta: {
          breadcrumb: "New vehicle",
        },
      },
      {
        path: "edit/:id",
        name: ROUTE_NAMES.VehiclesEdit,
        component: () => import("./views/VehiclesEdit.vue"),
        meta: {
          breadcrumb: "Edit Vehicle",
        },
      },
    ],
  },
];
