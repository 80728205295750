export enum UserRole {
  Superadmin = 1,
  Companyadmin = 2,
  Manager = 3,
  Assistant = 4,
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  username: string;
  email?: string;
  role?: UserRole;
}
