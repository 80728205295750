import { ElNotification } from "element-plus";
import { createRouter, createWebHistory } from "vue-router";

import {
  ROUTE_NAMES as ADMIN_ROUTE_NAMES,
  routes as adminRoutes,
} from "@/modules/admin";
import {
  ROUTE_NAMES as AUDITOR_ROUTE_NAMES,
  routes as auditorRoutes,
} from "@/modules/auditor";
import {
  ROUTE_NAMES as AUTH_ROUTE_NAMES,
  routes as authRoutes,
} from "@/modules/auth";
import { routes as complaintsRoutes } from "@/modules/complaints";
import { routes as devicesRoutes } from "@/modules/devices";
import { routes as driversRoutes } from "@/modules/drivers";
import { routes as logsRoutes } from "@/modules/logs";
import { routes as reportRoutes } from "@/modules/reports";
import { routes as trackingRoutes } from "@/modules/tracking";
import { routes as usersRoutes } from "@/modules/users";
import { routes as vehicleRoutes } from "@/modules/vehicles";
import { ability } from "@/services/ability";
import { Subject } from "@/services/ability";
import { useAuthStore } from "@/stores/auth";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/:companyId(\\d+)?",
      name: "dashboard",
      component: () => import("@/layouts/Default.vue"),
      redirect: {
        name: "homepage",
      },
      meta: {
        requiresAuth: true,
        subject: Subject.MainDashboard,
      },
      children: [
        {
          name: "homepage",
          path: "",
          component: () => import("@/views/HomeView.vue"),
        },

        ...logsRoutes,
        ...reportRoutes,
        ...complaintsRoutes,
        ...trackingRoutes,
        ...driversRoutes,
        ...vehicleRoutes,
        ...usersRoutes,
        ...devicesRoutes,
        {
          path: "resource",
          name: "resource",
          component: () => import("@/views/ResourcePage.vue"),
        },
        {
          path: "profile",
          name: "profile",
          component: () => import("@/views/profile/ProfilePage.vue"),
          meta: {
            breadcrumb: "Profile",
          },
          children: [
            {
              path: "",
              name: "profile.main",
              component: () => import("@/views/profile/ProfileMain.vue"),
            },
            {
              path: "change-password",
              name: "profile.change-password",
              components: {
                default: () => import("@/views/profile/ProfileMain.vue"),
                modal: () =>
                  import("@/views/profile/ProfileChangePassword.vue"),
              },
            },
          ],
        },
        {
          path: "maintenance",
          name: "maintenance",
          component: () => import("@/views/maintenance/MaintenancePage.vue"),
          meta: {
            breadcrumb: "Maintenance",
          },
        },
        {
          path: "chat",
          name: "chat",
          component: () => import("@/views/chat/ChatMain.vue"),
          meta: {
            breadcrumb: "Chat",
          },
          children: [
            {
              path: "messages/:chatId",
              name: "chat.user",
              components: {
                default: () => import("@/views/chat/ChatMain.vue"),
                chat: () => import("@/views/chat/ChatSect.vue"),
              },
            },
          ],
        },
        {
          path: "settings",
          name: "settings",
          component: () => import("@/views/settings/SettingsPage.vue"),
          meta: {
            breadcrumb: "Settings",
          },
        },
      ],
    },
    ...authRoutes,
    ...auditorRoutes,
    ...adminRoutes,
    {
      path: "/access-denied",
      name: "accessDenied",
      component: () => import("@/views/AccessDeniedPage.vue"),
    },
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: () => import("@/views/NotFoundPage.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();

  const authRequired = to.matched.some((route) => route.meta.requiresAuth);
  if (authRequired && !authStore.isLoggedIn) {
    next({ name: AUTH_ROUTE_NAMES.Login, query: { to: to.path } });
  } else {
    const routeWithSubject = to.matched.find((route) => route.meta.subject);
    const isMainDashboard = to.matched.some(
      (route) => route.name === "dashboard"
    );
    const subject = routeWithSubject?.meta.subject;

    if (subject && ability.cannot("view", subject)) {
      next({ name: "accessDenied" });
    } else if (!to.params.companyId && authStore.isAuditor && isMainDashboard) {
      ElNotification({
        title: "Please select company",
        type: "info",
      });
      next({
        name: AUDITOR_ROUTE_NAMES.Companies,
        query: {
          selectedTab: "companies",
        },
      });
    } else if (!to.params.companyId && authStore.isAdmin && isMainDashboard) {
      next({
        name: ADMIN_ROUTE_NAMES.Managers,
      });
    } else {
      next();
    }
  }
});

export default router;
