export const ROUTE_BASE_NAME = "reports";

export const ROUTE_NAMES = {
  ReportsMain: `${ROUTE_BASE_NAME}`,
  ReportsList: `${ROUTE_BASE_NAME}.list`,
  ReportsInProgress: `${ROUTE_BASE_NAME}.in-progress`,
  ReportsInProgressList: `${ROUTE_BASE_NAME}.in-progress.list`,
  ReportsInProgressView: `${ROUTE_BASE_NAME}.in-progress.view`,
  ReportsActivationReport: `${ROUTE_BASE_NAME}.activation-report`,
  ReportsActivationList: `${ROUTE_BASE_NAME}.activation.list`,
  ReportsFmcsa: `${ROUTE_BASE_NAME}.fmcsa`,
  ReportsIfta: `${ROUTE_BASE_NAME}.ifta`,
  ReportUpdate: `${ROUTE_BASE_NAME}.update`,
  ReportState: `${ROUTE_BASE_NAME}.state`,
};
