export const ROUTE_BASE_NAME = "admin";

export const ROUTE_NAMES = {
  Update: `${ROUTE_BASE_NAME}.update`,
  ChangePassword: `${ROUTE_BASE_NAME}.change-password`,
  Main: `${ROUTE_BASE_NAME}.main`,
  Managers: `${ROUTE_BASE_NAME}.managers`,
  Companies: `${ROUTE_BASE_NAME}.companies`,
  CreateUser: `${ROUTE_BASE_NAME}.user.create`,
};
