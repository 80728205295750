export const ROUTE_BASE_NAME = "auth";

export const ROUTE_NAMES = {
  Login: `${ROUTE_BASE_NAME}.login`,
  AutoLogin: `${ROUTE_BASE_NAME}.auto-login`,
  SignUp: `${ROUTE_BASE_NAME}.sign-up`,
  SignUpStep1: `${ROUTE_BASE_NAME}.sign-up.step-1`,
  SignUpStep2: `${ROUTE_BASE_NAME}.sign-up.step-2`,
  SignUpStep3: `${ROUTE_BASE_NAME}.sign-up.step-3`,
  ForgotPassword: `${ROUTE_BASE_NAME}.forgot-password`,
};
