import { useQuery } from "@tanstack/vue-query";
import type { MaybeRef } from "@vueuse/core";

import type { ApiClient } from "@/api-client";
import type { ApiResponse } from "@/types/common";
import type { Company } from "@/types/company";
import { ApiClientKey } from "@/types/symbols";

export const useCompanyById = (id: MaybeRef<number | null>) => {
  const apiClient = inject(ApiClientKey) as ApiClient;
  const enabled = computed(() => Boolean(unref(id)));
  return useQuery(
    ["company", id],
    async () => {
      if (!unref(id)) {
        return null;
      }
      const { data } = await apiClient.$get<ApiResponse<Company, "single">>(
        `/companies/company`,
        {
          params: { id: unref(id)?.toString() },
        }
      );
      return data;
    },
    {
      enabled,
    }
  );
};
