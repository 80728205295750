import { ElLoading } from "element-plus";

import type { PluginContext } from "@/types/common";

export default function ({ router }: PluginContext) {
  let loading: any;
  router.beforeEach((to, from, next) => {
    loading = ElLoading.service({ lock: true });
    next();
  });

  router.beforeResolve((to, from, next) => {
    loading.close();
    next();
  });
}
