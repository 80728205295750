import type { App } from "vue";
import type { LocationQueryRaw, RouteLocationRaw, RouteParamsRaw } from "vue-router";

import router from "@/router";
import { GetRouterToKey } from "@/types/symbols";
import { DateTime, Duration } from "@/utils/datetime";

export function $index(index: number, page: number, limit: number): number {
  return (page - 1) * limit + index + 1;
}

export function $formatDate(date: string) {
  return DateTime.from(date).format("YYYY-MM-DD");
}

export function $formatTime(date: string, format?: string) {
  return DateTime.parse(date, format).format("HH:mm");
}
export function $formatDateTime(date: string) {
  return DateTime.from(date).format("YYYY-MM-DD HH:mm A");
}
export function $getHourTime(seconds: number): string {
  return Duration.create(seconds, "seconds").format("H");
}
export function $formatToHour(seconds: number): string {
  return Duration.create(seconds, "seconds").format("HH:mm");
}

export function $getRouterTo(rawLocation: {
  name?: string;
  params?: RouteParamsRaw;
  query?: LocationQueryRaw;
}): RouteLocationRaw {
  const route = router.resolve({
    path: window.location.pathname,
  });
  if (route.params?.companyId) {
    if (!rawLocation.params) {
      rawLocation.params = {};
    }

    rawLocation.params = {
      ...rawLocation.params,
      companyId: rawLocation.params.companyId || route.params.companyId,
    };
  }
  return rawLocation;
}

export default function ({ app }: { app: App }) {
  app.config.globalProperties.$index = $index;
  app.config.globalProperties.$getRouterTo = $getRouterTo;
  app.config.globalProperties.$formatDate = $formatDate;
  app.config.globalProperties.$formatDateTime = $formatDateTime;
  app.config.globalProperties.$formatTime = $formatTime;
  app.provide(GetRouterToKey, $getRouterTo);
}
