import type { RouteRecordRaw } from "vue-router";

import { ROUTE_BASE_NAME, ROUTE_NAMES } from "./config";

export const routes: RouteRecordRaw[] = [
  {
    path: "/admin",
    name: ROUTE_BASE_NAME,
    component: () => import("./AdminMain.vue"),
    redirect: { name: ROUTE_NAMES.Main },
    meta: { requiresAuth: true },
    children: [
      {
        path: "edit/:id",
        name: ROUTE_NAMES.Update,
        component: () => import("./views/edit/AdminEdit.vue"),
        meta: {
          breadcrumb: "Edit User",
        },
      },
      {
        path: "edit/:id/change-password",
        name: ROUTE_NAMES.ChangePassword,
        components: {
          default: () => import("./views/edit/AdminEdit.vue"),
          modal: () => import("./views/edit/AdminChangePassword.vue"),
        },
      },
      {
        path: "",
        name: ROUTE_NAMES.Main,
        component: () => import("./views/AdminPage.vue"),
        redirect: { name: ROUTE_NAMES.Managers },
        children: [
          {
            path: "managers",
            name: ROUTE_NAMES.Managers,
            component: () => import("./views/AdminManagers.vue"),
          },
          {
            path: "companies",
            name: ROUTE_NAMES.Companies,
            component: () => import("./views/AdminCompanies.vue"),
          },
        ],
      },
      {
        path: "users/create",
        name: ROUTE_NAMES.CreateUser,
        components: {
          default: () => import("./views/AdminPage.vue"),
          modal: () => import("./views/AdminAddUser.vue"),
        },
      },
    ],
  },
];
