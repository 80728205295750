import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

export type DurationUnit =
  | "millisecond"
  | "milliseconds"
  | "second"
  | "seconds"
  | "minute"
  | "minutes"
  | "hour"
  | "hours"
  | "day"
  | "days"
  | "month"
  | "months"
  | "year"
  | "years";

export class DateTime {
  private constructor(instance: Dayjs) {
    this.instance = instance;
  }

  private instance!: Dayjs;
  format(format: string): string {
    return this.instance.format(format);
  }

  public static parse(dateTimeString: string, format?: string): DateTime {
    return new DateTime(dayjs.tz(dayjs(dateTimeString, format)));
  }

  public static from(value?: string | number | Date | DateTime): DateTime {
    if (value instanceof DateTime) {
      return value;
    }
    if (value) {
      return new DateTime(dayjs.tz(value));
    }
    return new DateTime(dayjs.tz(dayjs()));
  }

  public static now() {
    return new DateTime(dayjs.tz(dayjs().valueOf()));
  }

  public getMilliseconds(): number {
    return this.instance.valueOf();
  }

  public add(value: number, unit: DurationUnit): DateTime {
    return new DateTime(this.instance.add(value, unit));
  }

  public subtract(value: number, unit: DurationUnit): DateTime {
    return new DateTime(this.instance.subtract(value, unit));
  }

  public isBefore(value: number | string | Date | DateTime): boolean {
    const target = DateTime.from(value);
    return this.instance.isBefore(target.getMilliseconds());
  }

  public isAfter(value: number | string | Date | DateTime): boolean {
    const target = DateTime.from(value);
    return this.instance.isAfter(target.getMilliseconds());
  }

  public isSame(value: number | string | Date | DateTime): boolean {
    const target = DateTime.from(value);
    return this.instance.isSame(target.getMilliseconds());
  }

  public isSameOrBefore(value: number | string | Date | DateTime): boolean {
    const target = DateTime.from(value);
    return this.instance.isSameOrBefore(target.getMilliseconds());
  }

  public isSameOrAfter(value: number | string | Date | DateTime): boolean {
    const target = DateTime.from(value);
    return this.instance.isSameOrAfter(target.getMilliseconds());
  }

  public startOf(unit: DurationUnit): DateTime {
    return new DateTime(this.instance.startOf(unit));
  }

  public endOf(unit: DurationUnit): DateTime {
    return new DateTime(this.instance.endOf(unit));
  }

  public diff(
    date: string | number | DateTime | Date | null,
    unit?: DurationUnit
  ): number {
    if (date instanceof DateTime) {
      return this.instance.diff(date.getMilliseconds(), unit);
    }
    return this.instance.diff(date, unit);
  }

  public static getTimeZone(): string {
    return dayjs.tz.guess();
  }

  public static setTimeZone(timezone: string) {
    dayjs.tz.setDefault(timezone);
  }
}

export class Duration {
  private constructor(private instance: ReturnType<typeof dayjs.duration>) {}
  public static create(time: number, unit?: DurationUnit) {
    return new Duration(dayjs.duration(time, unit));
  }

  public format(formatStr: string): string {
    return this.instance.format(formatStr);
  }

  public hours(): number {
    return this.instance.hours();
  }

  public asHours() {
    return this.instance.asHours();
  }

  public minutes(): number {
    return this.instance.minutes();
  }

  public seconds(): number {
    return this.instance.seconds();
  }

  public humanize(): string {
    return this.instance.humanize();
  }
}
