import { defineStore } from "pinia";

import type { Company } from "@/types/company";
import { getTimezoneName } from "@/utils/timezone";

import { apiClient } from "./../api-client/index";

export const useCompanyStore = defineStore("company", {
  state: () => ({
    currentCompany: null as Company | null,
    currentCompanyId: null as null | number,
  }),
  getters: {
    timezoneString(state): string | null | undefined {
      if (state.currentCompany) {
        const timezoneName = getTimezoneName(
          -1 * state.currentCompany?.timezone
        );
        return timezoneName;
      }
      return null;
    },
    timezoneOffset(state): string | null | undefined {
      if (state.currentCompany) {
        return `-${state.currentCompany.timezone / 60}`;
      }
      return null;
    },
  },
  actions: {
    fetchCompany() {
      apiClient.get<{ data: Company }>("companies/company").then((response) => {
        this.currentCompany = response.data.data;
        this.currentCompanyId = response.data.data.id;
      });
    },
  },
});
