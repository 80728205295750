import type { RouteRecordRaw } from "vue-router";

import { Subject } from "@/services/ability";

import { ROUTE_BASE_NAME, ROUTE_NAMES } from "./config";

export const routes: RouteRecordRaw[] = [
  {
    path: "/auditor",
    name: ROUTE_BASE_NAME,
    component: () => import("./AuditorMain.vue"),
    redirect: { name: ROUTE_NAMES.Main },
    meta: { requiresAuth: true },
    children: [
      {
        path: "edit/:id",
        name: ROUTE_NAMES.Update,
        component: () => import("./views/edit/AuditorEdit.vue"),
        meta: {
          breadcrumb: "Edit User",
          subject: Subject.Auditor,
        },
      },
      {
        path: "edit/:id/change-password",
        name: ROUTE_NAMES.ChangePassword,
        components: {
          default: () => import("./views/edit/AuditorEdit.vue"),
          modal: () => import("./views/edit/AuditorChangePassword.vue"),
        },
        meta: {
          subject: Subject.Auditor,
        },
      },
      {
        path: "",
        name: ROUTE_NAMES.Main,
        component: () => import("./views/AuditorPage.vue"),
        redirect: { name: ROUTE_NAMES.Assistants },
        children: [
          {
            path: "assistants",
            name: ROUTE_NAMES.Assistants,
            component: () => import("./views/AuditorAssistants.vue"),
            meta: {
              subject: Subject.Auditor,
            },
          },
          {
            path: "companies",
            name: ROUTE_NAMES.Companies,
            component: () => import("./views/AuditorCompanies.vue"),
          },
        ],
      },
      {
        path: "users/create",
        name: ROUTE_NAMES.CreateUser,
        components: {
          default: () => import("./views/AuditorPage.vue"),
          modal: () => import("./views/AuditorAddUser.vue"),
        },
        meta: {
          subject: Subject.Auditor,
        },
      },
    ],
  },
];
