import type { RouteRecordRaw } from "vue-router";

import { ROUTE_BASE_NAME, ROUTE_NAMES } from "./config";

export const routes: RouteRecordRaw[] = [
  {
    path: "tracking",
    name: ROUTE_BASE_NAME,
    component: () => import("./views/TrackingPage.vue"),
    meta: {
      breadcrumb: "Tracking",
    },
    redirect: { name: ROUTE_NAMES.trackingList },
    children: [
      {
        path: "",
        name: ROUTE_NAMES.trackingList,
        component: () => import("./views/TrackingList.vue"),
      },
      {
        path: ":id",
        name: ROUTE_NAMES.trackingLocation,
        component: () => import("./views/TrackingDriversLocation.vue"),
        meta: {
          breadcrumb: "Location",
        },
      },
    ],
  },
];
