const timezones = [
  {
    offset: -540,
    timezone: "America/Adak",
  },
  {
    offset: -480,
    timezone: "America/Anchorage",
  },
  {
    offset: -240,
    timezone: "America/Anguilla",
  },
  {
    offset: -240,
    timezone: "America/Antigua",
  },
  {
    offset: -180,
    timezone: "America/Araguaina",
  },
  {
    offset: -180,
    timezone: "America/Argentina/Buenos_Aires",
  },
  {
    offset: -180,
    timezone: "America/Argentina/Catamarca",
  },
  {
    offset: -180,
    timezone: "America/Argentina/ComodRivadavia",
  },
  {
    offset: -180,
    timezone: "America/Argentina/Cordoba",
  },
  {
    offset: -180,
    timezone: "America/Argentina/Jujuy",
  },
  {
    offset: -180,
    timezone: "America/Argentina/La_Rioja",
  },
  {
    offset: -180,
    timezone: "America/Argentina/Mendoza",
  },
  {
    offset: -180,
    timezone: "America/Argentina/Rio_Gallegos",
  },
  {
    offset: -180,
    timezone: "America/Argentina/Salta",
  },
  {
    offset: -180,
    timezone: "America/Argentina/San_Juan",
  },
  {
    offset: -180,
    timezone: "America/Argentina/San_Luis",
  },
  {
    offset: -180,
    timezone: "America/Argentina/Tucuman",
  },
  {
    offset: -180,
    timezone: "America/Argentina/Ushuaia",
  },
  {
    offset: -240,
    timezone: "America/Aruba",
  },
  {
    offset: -240,
    timezone: "America/Asuncion",
  },
  {
    offset: -300,
    timezone: "America/Atikokan",
  },
  {
    offset: -540,
    timezone: "America/Atka",
  },
  {
    offset: -180,
    timezone: "America/Bahia",
  },
  {
    offset: -360,
    timezone: "America/Bahia_Banderas",
  },
  {
    offset: -240,
    timezone: "America/Barbados",
  },
  {
    offset: -180,
    timezone: "America/Belem",
  },
  {
    offset: -360,
    timezone: "America/Belize",
  },
  {
    offset: -240,
    timezone: "America/Blanc-Sablon",
  },
  {
    offset: -240,
    timezone: "America/Boa_Vista",
  },
  {
    offset: -300,
    timezone: "America/Bogota",
  },
  {
    offset: -360,
    timezone: "America/Boise",
  },
  {
    offset: -180,
    timezone: "America/Buenos_Aires",
  },
  {
    offset: -360,
    timezone: "America/Cambridge_Bay",
  },
  {
    offset: -240,
    timezone: "America/Campo_Grande",
  },
  {
    offset: -300,
    timezone: "America/Cancun",
  },
  {
    offset: -240,
    timezone: "America/Caracas",
  },
  {
    offset: -180,
    timezone: "America/Catamarca",
  },
  {
    offset: -180,
    timezone: "America/Cayenne",
  },
  {
    offset: -300,
    timezone: "America/Cayman",
  },
  {
    offset: -300,
    timezone: "America/Chicago",
  },
  {
    offset: -360,
    timezone: "America/Chihuahua",
  },
  {
    offset: -360,
    timezone: "America/Ciudad_Juarez",
  },
  {
    offset: -300,
    timezone: "America/Coral_Harbour",
  },
  {
    offset: -180,
    timezone: "America/Cordoba",
  },
  {
    offset: -360,
    timezone: "America/Costa_Rica",
  },
  {
    offset: -420,
    timezone: "America/Creston",
  },
  {
    offset: -240,
    timezone: "America/Cuiaba",
  },
  {
    offset: -240,
    timezone: "America/Curacao",
  },
  {
    offset: 0,
    timezone: "America/Danmarkshavn",
  },
  {
    offset: -420,
    timezone: "America/Dawson",
  },
  {
    offset: -420,
    timezone: "America/Dawson_Creek",
  },
  {
    offset: -360,
    timezone: "America/Denver",
  },
  {
    offset: -240,
    timezone: "America/Detroit",
  },
  {
    offset: -240,
    timezone: "America/Dominica",
  },
  {
    offset: -360,
    timezone: "America/Edmonton",
  },
  {
    offset: -300,
    timezone: "America/Eirunepe",
  },
  {
    offset: -360,
    timezone: "America/El_Salvador",
  },
  {
    offset: -420,
    timezone: "America/Ensenada",
  },
  {
    offset: -420,
    timezone: "America/Fort_Nelson",
  },
  {
    offset: -240,
    timezone: "America/Fort_Wayne",
  },
  {
    offset: -180,
    timezone: "America/Fortaleza",
  },
  {
    offset: -180,
    timezone: "America/Glace_Bay",
  },
  {
    offset: -120,
    timezone: "America/Godthab",
  },
  {
    offset: -180,
    timezone: "America/Goose_Bay",
  },
  {
    offset: -240,
    timezone: "America/Grand_Turk",
  },
  {
    offset: -240,
    timezone: "America/Grenada",
  },
  {
    offset: -240,
    timezone: "America/Guadeloupe",
  },
  {
    offset: -360,
    timezone: "America/Guatemala",
  },
  {
    offset: -300,
    timezone: "America/Guayaquil",
  },
  {
    offset: -240,
    timezone: "America/Guyana",
  },
  {
    offset: -180,
    timezone: "America/Halifax",
  },
  {
    offset: -240,
    timezone: "America/Havana",
  },
  {
    offset: -420,
    timezone: "America/Hermosillo",
  },
  {
    offset: -240,
    timezone: "America/Indiana/Indianapolis",
  },
  {
    offset: -300,
    timezone: "America/Indiana/Knox",
  },
  {
    offset: -240,
    timezone: "America/Indiana/Marengo",
  },
  {
    offset: -240,
    timezone: "America/Indiana/Petersburg",
  },
  {
    offset: -300,
    timezone: "America/Indiana/Tell_City",
  },
  {
    offset: -240,
    timezone: "America/Indiana/Vevay",
  },
  {
    offset: -240,
    timezone: "America/Indiana/Vincennes",
  },
  {
    offset: -240,
    timezone: "America/Indiana/Winamac",
  },
  {
    offset: -240,
    timezone: "America/Indianapolis",
  },
  {
    offset: -360,
    timezone: "America/Inuvik",
  },
  {
    offset: -240,
    timezone: "America/Iqaluit",
  },
  {
    offset: -300,
    timezone: "America/Jamaica",
  },
  {
    offset: -180,
    timezone: "America/Jujuy",
  },
  {
    offset: -480,
    timezone: "America/Juneau",
  },
  {
    offset: -240,
    timezone: "America/Kentucky/Louisville",
  },
  {
    offset: -240,
    timezone: "America/Kentucky/Monticello",
  },
  {
    offset: -300,
    timezone: "America/Knox_IN",
  },
  {
    offset: -240,
    timezone: "America/Kralendijk",
  },
  {
    offset: -240,
    timezone: "America/La_Paz",
  },
  {
    offset: -300,
    timezone: "America/Lima",
  },
  {
    offset: -420,
    timezone: "America/Los_Angeles",
  },
  {
    offset: -240,
    timezone: "America/Louisville",
  },
  {
    offset: -240,
    timezone: "America/Lower_Princes",
  },
  {
    offset: -180,
    timezone: "America/Maceio",
  },
  {
    offset: -360,
    timezone: "America/Managua",
  },
  {
    offset: -240,
    timezone: "America/Manaus",
  },
  {
    offset: -240,
    timezone: "America/Marigot",
  },
  {
    offset: -240,
    timezone: "America/Martinique",
  },
  {
    offset: -300,
    timezone: "America/Matamoros",
  },
  {
    offset: -420,
    timezone: "America/Mazatlan",
  },
  {
    offset: -180,
    timezone: "America/Mendoza",
  },
  {
    offset: -300,
    timezone: "America/Menominee",
  },
  {
    offset: -360,
    timezone: "America/Merida",
  },
  {
    offset: -480,
    timezone: "America/Metlakatla",
  },
  {
    offset: -360,
    timezone: "America/Mexico_City",
  },
  {
    offset: -120,
    timezone: "America/Miquelon",
  },
  {
    offset: -180,
    timezone: "America/Moncton",
  },
  {
    offset: -360,
    timezone: "America/Monterrey",
  },
  {
    offset: -180,
    timezone: "America/Montevideo",
  },
  {
    offset: -240,
    timezone: "America/Montreal",
  },
  {
    offset: -240,
    timezone: "America/Montserrat",
  },
  {
    offset: -240,
    timezone: "America/Nassau",
  },
  {
    offset: -240,
    timezone: "America/New_York",
  },
  {
    offset: -240,
    timezone: "America/Nipigon",
  },
  {
    offset: -480,
    timezone: "America/Nome",
  },
  {
    offset: -120,
    timezone: "America/Noronha",
  },
  {
    offset: -300,
    timezone: "America/North_Dakota/Beulah",
  },
  {
    offset: -300,
    timezone: "America/North_Dakota/Center",
  },
  {
    offset: -300,
    timezone: "America/North_Dakota/New_Salem",
  },
  {
    offset: -120,
    timezone: "America/Nuuk",
  },
  {
    offset: -300,
    timezone: "America/Ojinaga",
  },
  {
    offset: -300,
    timezone: "America/Panama",
  },
  {
    offset: -240,
    timezone: "America/Pangnirtung",
  },
  {
    offset: -180,
    timezone: "America/Paramaribo",
  },
  {
    offset: -420,
    timezone: "America/Phoenix",
  },
  {
    offset: -240,
    timezone: "America/Port-au-Prince",
  },
  {
    offset: -240,
    timezone: "America/Port_of_Spain",
  },
  {
    offset: -300,
    timezone: "America/Porto_Acre",
  },
  {
    offset: -240,
    timezone: "America/Porto_Velho",
  },
  {
    offset: -240,
    timezone: "America/Puerto_Rico",
  },
  {
    offset: -180,
    timezone: "America/Punta_Arenas",
  },
  {
    offset: -300,
    timezone: "America/Rainy_River",
  },
  {
    offset: -300,
    timezone: "America/Rankin_Inlet",
  },
  {
    offset: -180,
    timezone: "America/Recife",
  },
  {
    offset: -360,
    timezone: "America/Regina",
  },
  {
    offset: -300,
    timezone: "America/Resolute",
  },
  {
    offset: -300,
    timezone: "America/Rio_Branco",
  },
  {
    offset: -180,
    timezone: "America/Rosario",
  },
  {
    offset: -420,
    timezone: "America/Santa_Isabel",
  },
  {
    offset: -180,
    timezone: "America/Santarem",
  },
  {
    offset: -240,
    timezone: "America/Santiago",
  },
  {
    offset: -240,
    timezone: "America/Santo_Domingo",
  },
  {
    offset: -180,
    timezone: "America/Sao_Paulo",
  },
  {
    offset: 0,
    timezone: "America/Scoresbysund",
  },
  {
    offset: -360,
    timezone: "America/Shiprock",
  },
  {
    offset: -480,
    timezone: "America/Sitka",
  },
  {
    offset: -240,
    timezone: "America/St_Barthelemy",
  },
  {
    offset: -150,
    timezone: "America/St_Johns",
  },
  {
    offset: -240,
    timezone: "America/St_Kitts",
  },
  {
    offset: -240,
    timezone: "America/St_Lucia",
  },
  {
    offset: -240,
    timezone: "America/St_Thomas",
  },
  {
    offset: -240,
    timezone: "America/St_Vincent",
  },
  {
    offset: -360,
    timezone: "America/Swift_Current",
  },
  {
    offset: -360,
    timezone: "America/Tegucigalpa",
  },
  {
    offset: -180,
    timezone: "America/Thule",
  },
  {
    offset: -240,
    timezone: "America/Thunder_Bay",
  },
  {
    offset: -420,
    timezone: "America/Tijuana",
  },
  {
    offset: -240,
    timezone: "America/Toronto",
  },
  {
    offset: -240,
    timezone: "America/Tortola",
  },
  {
    offset: -420,
    timezone: "America/Vancouver",
  },
  {
    offset: -240,
    timezone: "America/Virgin",
  },
  {
    offset: -420,
    timezone: "America/Whitehorse",
  },
  {
    offset: -300,
    timezone: "America/Winnipeg",
  },
  {
    offset: -480,
    timezone: "America/Yakutat",
  },
  {
    offset: -360,
    timezone: "America/Yellowknife",
  },
];

export function getTimezoneName(offset: number): string | undefined {
  return timezones.find((timezone) => timezone.offset === offset)?.timezone;
}
