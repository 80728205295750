import axios, {
  AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
} from "axios";
import { camelCase, isArray, isObject, snakeCase, transform } from "lodash";

import { useAuthStore } from "@/stores/auth";
import { useCompanyStore } from "@/stores/company";

const toCamelCase = (obj: Record<string, unknown>) =>
  transform(
    obj,
    (result: Record<string, unknown>, value: unknown, key: string, target) => {
      const camelKey = isArray(target) ? key : camelCase(key);
      result[camelKey] = isObject(value)
        ? toCamelCase(value as Record<string, unknown>)
        : value;
    }
  );

const toSnakeCase = (obj: Record<string, unknown>) =>
  transform(
    obj,
    (result: Record<string, unknown>, value: unknown, key: string, target) => {
      const camelKey = isArray(target) ? key : snakeCase(key);
      result[camelKey] = isObject(value)
        ? toSnakeCase(value as Record<string, unknown>)
        : value;
    }
  );

const client = axios.create({
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
  timeout: Number(import.meta.env.VITE_APP_API_TIMEOUT || 3000),
});

client.interceptors.request.use((config: AxiosRequestConfig) => {
  const authStore = useAuthStore();
  const companyStore = useCompanyStore();

  if (!config.headers) {
    config.headers = {};
  }
  if (authStore.accessToken) {
    config.headers.token = authStore.accessToken;
  }

  if (companyStore.currentCompanyId) {
    config.headers.company = companyStore.currentCompanyId;
  }

  if (config.data) {
    config.data = toSnakeCase(config.data);
  }
  if (config.params) {
    config.params = toSnakeCase(config.params);
  }
  return config;
});

client.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data) {
      response.data = toCamelCase(response.data);
    }
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.data) {
      error.response.data = toCamelCase(error.response.data as any);
    }

    throw error;
  }
);

export { client };
