import { PureAbility } from "@casl/ability";

import { type User, UserRole } from "@/types/user";

export enum Subject {
  MainDashboard = "MainDashboard",
  ManagingDashboard = "ManagingDashboard",
  Auditor = "Auditor",
  Driver = "Driver",
  Transaction = "Transaction",
  User = "User",
  AllUnits = "AllUnits",
  Logs = "Logs",
  Reports = "Reports",
  Tracking = "Tracking",
  Complaints = "Complaints",
  Vehicle = "Vehicle",
  Maintenance = "Maintenance",
  Chat = "Chat",
  Resource = "Resource",
  Settings = "Settings",
  ProfileChangePassword = "ProfileChangePassword",
  Device = "Device",
}

export enum Action {
  View = "view",
  Create = "create",
  Update = "update",
  Delete = "delete",
  Open = "open",
}

const subjects = [
  // Main dashboard subject
  {
    name: Subject.MainDashboard,
    actions: [
      {
        name: Action.View,
        roles: [
          UserRole.Companyadmin,
          UserRole.Assistant,
          UserRole.Manager,
          UserRole.Superadmin,
        ],
      },
    ],
  },
  // Managing dashboard
  {
    name: Subject.ManagingDashboard,
    actions: [
      {
        name: Action.View,
        roles: [UserRole.Manager, UserRole.Assistant],
      },
    ],
  },
  // Auditor
  {
    name: Subject.Auditor,
    actions: [
      { name: Action.View, roles: [UserRole.Superadmin, UserRole.Manager] },
    ],
  },
  // Driver
  {
    name: Subject.Driver,
    actions: [
      {
        name: Action.View,
        roles: [UserRole.Companyadmin, UserRole.Assistant, UserRole.Manager],
      },
      {
        name: Action.Create,
        roles: [UserRole.Companyadmin, UserRole.Assistant, UserRole.Manager],
      },
      {
        name: Action.Delete,
        roles: [UserRole.Companyadmin, UserRole.Assistant, UserRole.Manager],
      },
      {
        name: Action.Update,
        roles: [UserRole.Companyadmin, UserRole.Assistant, UserRole.Manager],
      },
    ],
  },
  // Device
  {
    name: Subject.Device,
    actions: [
      {
        name: Action.View,
        roles: [UserRole.Companyadmin, UserRole.Assistant, UserRole.Manager],
      },
      {
        name: Action.Create,
        roles: [UserRole.Companyadmin, UserRole.Assistant, UserRole.Manager],
      },
      {
        name: Action.Delete,
        roles: [UserRole.Companyadmin, UserRole.Assistant, UserRole.Manager],
      },
      {
        name: Action.Update,
        roles: [UserRole.Companyadmin, UserRole.Assistant, UserRole.Manager],
      },
    ],
  },
  // Transaction
  {
    name: Subject.Transaction,
    actions: [
      { name: Action.Open, roles: [UserRole.Assistant, UserRole.Manager] },
    ],
  },
  // User
  {
    name: Subject.User,
    actions: [
      { name: Action.View, roles: [UserRole.Companyadmin, UserRole.Manager] },
      { name: Action.Create, roles: [UserRole.Companyadmin, UserRole.Manager] },
      { name: Action.Update, roles: [UserRole.Companyadmin, UserRole.Manager] },
      { name: Action.Delete, roles: [UserRole.Companyadmin, UserRole.Manager] },
    ],
  },
  // All Units
  {
    name: Subject.AllUnits,
    actions: [
      {
        name: Action.View,
        roles: [UserRole.Companyadmin, UserRole.Assistant, UserRole.Manager],
      },
    ],
  },
  // All Logs,
  {
    name: Subject.Logs,
    actions: [
      {
        name: Action.View,
        roles: [UserRole.Assistant, UserRole.Manager, UserRole.Companyadmin],
      },
    ],
  },
  // Reports
  {
    name: Subject.Reports,
    actions: [
      {
        name: Action.View,
        roles: [UserRole.Assistant, UserRole.Manager],
      },
    ],
  },
  // Tracking
  {
    name: Subject.Tracking,
    actions: [
      {
        name: Action.View,
        roles: [UserRole.Assistant, UserRole.Manager],
      },
    ],
  },
  // Complaints
  {
    name: Subject.Complaints,
    actions: [
      {
        name: Action.View,
        roles: [UserRole.Assistant, UserRole.Manager],
      },
    ],
  },
  // Vehicle
  {
    name: Subject.Vehicle,
    actions: [
      {
        name: Action.View,
        roles: [UserRole.Companyadmin, UserRole.Assistant, UserRole.Manager],
      },
      {
        name: Action.Create,
        roles: [UserRole.Companyadmin, UserRole.Assistant, UserRole.Manager],
      },
      {
        name: Action.Update,
        roles: [UserRole.Companyadmin, UserRole.Assistant, UserRole.Manager],
      },
      {
        name: Action.Delete,
        roles: [UserRole.Companyadmin, UserRole.Assistant, UserRole.Manager],
      },
    ],
  },
  // Maintenance
  {
    name: Subject.Maintenance,
    actions: [
      {
        name: Action.View,
        roles: [
          UserRole.Companyadmin,
          UserRole.Manager,
          UserRole.Assistant,
          UserRole.Superadmin,
        ],
      },
    ],
  },
  // Chat
  {
    name: Subject.Chat,
    actions: [
      {
        name: Action.View,
        roles: [UserRole.Companyadmin, UserRole.Manager, UserRole.Assistant],
      },
    ],
  },
  // Resource
  {
    name: Subject.Resource,
    actions: [
      {
        name: Action.View,
        roles: [UserRole.Companyadmin, UserRole.Manager, UserRole.Assistant],
      },
    ],
  },
  // Settings
  {
    name: Subject.Settings,
    actions: [
      {
        name: Action.View,
        roles: [UserRole.Assistant, UserRole.Manager, UserRole.Superadmin],
      },
    ],
  },
  {
    name: Subject.ProfileChangePassword,
    actions: [
      {
        name: Action.View,
        roles: [UserRole.Superadmin, UserRole.Companyadmin, UserRole.Manager],
      },
    ],
  },
];

export const ability = new PureAbility();

export function defineAbilityFor(user: User) {
  const rules = subjects.flatMap((subject) =>
    subject.actions.map((action) => ({
      action: action.name.toString(),
      subject: subject.name.toString(),
      inverted: !user.role || !action.roles.includes(user.role),
    }))
  );
  ability.update(rules);
}
