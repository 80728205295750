import { useCompanyStore } from "@/stores/company";

export const title = ref("");

export const useTitleWithTemplate = () => {
  const computedTitle = computed(() => {
    const companyStore = useCompanyStore();
    let result = "NAVIG8";

    if (title.value) {
      result = `${result} - ${title.value}`;
    }
    if (companyStore.currentCompany) {
      result = `${result} | ${companyStore.currentCompany.title}`;
    }

    return result;
  });

  useTitle(computedTitle);
};
