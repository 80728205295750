import type { PluginContext } from "@/types/common";

export default function ({ app }: PluginContext) {
  app.directive("focus", {
    mounted: (el: HTMLElement) => {
      if (["input", "textarea"].includes(el.tagName.toLowerCase())) {
        el.focus();
      } else {
        const inputEl = el.querySelector("input,textarea") as HTMLInputElement;
        if (inputEl) {
          inputEl.focus();
        }
      }
    },
  });
}
