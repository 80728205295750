import type { RouteRecordRaw } from "vue-router";

import { ROUTE_BASE_NAME, ROUTE_NAMES } from "./config";

export const routes: RouteRecordRaw[] = [
  {
    path: "reports",
    name: ROUTE_BASE_NAME,
    component: () => import("./views/ReportsMain.vue"),
    redirect: { name: "reports.list" },
    meta: {
      breadcrumb: "Reports",
    },
    children: [
      {
        path: "",
        name: ROUTE_NAMES.ReportsList,
        component: () => import("./views/ReportsPage.vue"),
      },
      {
        path: "in-progress",
        name: ROUTE_NAMES.ReportsInProgress,
        component: () => import("./views/ReportsInProgress.vue"),
        redirect: { name: "reports.in-progress.list" },
        meta: {
          breadcrumb: "In progress",
        },
        children: [
          {
            name: ROUTE_NAMES.ReportsInProgressList,
            path: "",
            component: () => import("./views/ReportsInProgressList.vue"),
          },
          {
            name: ROUTE_NAMES.ReportsInProgressView,
            path: ":id",
            component: () => import("./views/ReportsInProgressView.vue"),
            meta: {
              breadcrumb: "View",
            },
          },
        ],
      },
      {
        path: "activation-report",
        name: ROUTE_NAMES.ReportsActivationReport,
        component: () => import("./views/ReportsActivation.vue"),
        redirect: {
          name: ROUTE_NAMES.ReportsActivationList,
        },
        meta: {
          breadcrumb: "Activation report",
        },
        children: [
          {
            name: ROUTE_NAMES.ReportsActivationList,
            path: "",
            component: () => import("./views/ReportsActivationList.vue"),
          },
        ],
      },
      {
        path: "fmcsa-report",
        name: ROUTE_NAMES.ReportsFmcsa,
        component: () => import("./views/ReportsFmcsa.vue"),
        meta: {
          breadcrumb: "FMCSA report",
        },
      },
      {
        path: "ifta-report",
        name: ROUTE_NAMES.ReportsIfta,
        component: () => import("./views/ReportsIfta.vue"),
        meta: {
          breadcrumb: "IFTA report",
        },
      },

      {
        path: "vehicle/:id",
        name: ROUTE_NAMES.ReportUpdate,
        component: () => import("./views/ReportsUpdate.vue"),
        meta: {
          breadcrumb: "Vehicle Report",
        },
      },

      {
        path: "state/:id",
        name: ROUTE_NAMES.ReportState,
        component: () => import("./views/ReportsState.vue"),
        meta: {
          breadcrumb: "State Report",
        },
      },
    ],
  },
];
