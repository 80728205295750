import type { RouteRecordRaw } from "vue-router";

import { ROUTE_BASE_NAME, ROUTE_NAMES } from "./config";

export const routes: RouteRecordRaw[] = [
  {
    path: "users",
    name: ROUTE_BASE_NAME,
    component: () => import("@/modules/users/views/UsersMain.vue"),
    redirect: { name: ROUTE_NAMES.List },
    meta: {
      breadcrumb: "Users",
    },
    children: [
      {
        path: "",
        name: ROUTE_NAMES.List,
        component: () => import("@/modules/users/views/UsersPage.vue"),
      },
    ],
  },
];
