import type { RouteRecordRaw } from "vue-router";

import { ROUTE_BASE_NAME, ROUTE_NAMES } from "./config";

export const routes: RouteRecordRaw[] = [
  {
    path: "complaints",
    name: ROUTE_BASE_NAME,
    component: () => import("@/modules/complaints/views/ComplaintsMain.vue"),
    meta: {
      breadcrumb: "Complaints",
    },
    redirect: { name: ROUTE_NAMES.List },
    children: [
      {
        path: "",
        name: ROUTE_NAMES.List,
        component: () =>
          import("@/modules/complaints/views/ComplaintsList.vue"),
      },
      {
        path: "disconnect/:id",
        name: ROUTE_NAMES.Disconnect,
        component: () =>
          import("@/modules/complaints/views/ComplaintsDisconnect.vue"),
        meta: {
          breadcrumb: "Disconnect",
        },
      },
      {
        path: "unidentified/:id",
        name: ROUTE_NAMES.Unidentified,
        component: () =>
          import("@/modules/complaints/views/ComplaintsUnidentified.vue"),
        meta: {
          breadcrumb: "Unidentified",
        },
      },
      {
        path: "unidentified/:id/assign",
        name: ROUTE_NAMES.AssignUnidentified,
        components: {
          default: () =>
            import("@/modules/complaints/views/ComplaintsUnidentified.vue"),
          assign: () =>
            import("@/modules/complaints/views/ComplaintAssignModal.vue"),
        },
        meta: {
          breadcrumb: "Unidentified",
        },
      },
    ],
  },
];
