import type { RouteRecordRaw } from "vue-router";

import { DateTime } from "@/utils/datetime";

import { ROUTE_BASE_NAME, ROUTE_NAMES } from "./config";

export const routes: RouteRecordRaw[] = [
  {
    path: "logs",
    name: ROUTE_BASE_NAME,
    component: () => import("@/modules/logs/views/LogsMain.vue"),
    meta: {
      breadcrumb: "Logs",
    },
    redirect: {
      name: ROUTE_NAMES.List,
    },
    children: [
      {
        path: "",
        name: ROUTE_NAMES.List,
        component: () => import("@/modules/logs/views/LogsPage.vue"),
      },
      {
        path: "driver/:id(\\d+)",
        name: ROUTE_NAMES.LogsDriver,
        component: () => import("@/modules/logs/driver/LogsDriverMain.vue"),
        meta: {
          breadcrumb: "Driver",
        },
        redirect(to) {
          return {
            name: ROUTE_NAMES.LogsDriverElog,
            params: {
              id: to.params.id,
              date: DateTime.now().format("YYYY-MM-DD"),
            },
          };
        },
        children: [
          {
            name: ROUTE_NAMES.LogsDriverElog,
            path: ":date(\\d{4}-\\d{2}-\\d{2})",
            component: () => import("@/modules/logs/driver/LogsDriverElog.vue"),
          },
          {
            name: ROUTE_NAMES.LogsDriverTransaction,
            path: "transaction/:transactionId(\\d+)",
            component: () =>
              import("@/modules/logs/driver/LogsDriverTransaction.vue"),
            meta: {
              breadcrumb: "Transaction",
            },
          },
        ],
      },
      {
        path: "print/:id",
        name: ROUTE_NAMES.LogsPrint,
        meta: {
          breadcrumb: "Print",
        },
        component: () => import("@/modules/logs/views/LogsDailyPrint.vue"),
      },
    ],
  },
];
