import type { HttpStatusCode } from "@/types/common";

import { client } from "./client";

export type ApiRequestHeaders = Record<string, string>;
export type ApiResponseHeaders = Record<string, string | undefined>;
export type ApiRequestQueryParams =
  | Record<string, string | string[] | undefined | null>
  | URLSearchParams;

export type ApiRequestMethod = "get" | "post" | "put" | "delete" | "patch";

export interface ApiResponse<T> {
  data: T;
  status: HttpStatusCode;
  headers: ApiResponseHeaders;
}

export interface ApiRequestConfig<T, B> {
  url?: string;
  method?: ApiRequestMethod;
  headers?: ApiRequestHeaders;
  params?: ApiRequestQueryParams;
  body?: B;
  timeout?: number;
  transformResponse?: (data: T) => unknown;
}

export interface ApiClient {
  request: <T, B = unknown>(
    config: ApiRequestConfig<T, B>
  ) => Promise<ApiResponse<T>>;
  get: <T, B = unknown>(
    url: string,
    config?: Partial<ApiRequestConfig<T, B>>
  ) => Promise<ApiResponse<T>>;
  $get: <T, B = unknown>(
    url: string,
    config?: Partial<ApiRequestConfig<T, B>>
  ) => Promise<T>;
  post: <T, B = unknown>(
    url: string,
    config?: Partial<ApiRequestConfig<T, B>>
  ) => Promise<ApiResponse<T>>;
  $post: <T, B = unknown>(
    url: string,
    config?: Partial<ApiRequestConfig<T, B>>
  ) => Promise<T>;
  put: <T, B = unknown>(
    url: string,
    config?: Partial<ApiRequestConfig<T, B>>
  ) => Promise<ApiResponse<T>>;
  $put: <T, B = unknown>(
    url: string,
    config?: Partial<ApiRequestConfig<T, B>>
  ) => Promise<T>;
  patch: <T, B = unknown>(
    url: string,
    config?: Partial<ApiRequestConfig<T, B>>
  ) => Promise<ApiResponse<T>>;
  $patch: <T, B = unknown>(
    url: string,
    config?: Partial<ApiRequestConfig<T, B>>
  ) => Promise<T>;
  delete: <T, B = unknown>(
    url: string,
    config?: Partial<ApiRequestConfig<T, B>>
  ) => Promise<ApiResponse<T>>;
  $delete: <T, B = unknown>(
    url: string,
    config?: Partial<ApiRequestConfig<T, B>>
  ) => Promise<T>;
}

export const apiClient: ApiClient = {
  request: async <T, B = unknown>(config: ApiRequestConfig<T, B> = {}) => {
    const response = await client.request({
      url: config.url,
      method: config.method || "get",
      headers: config.headers || {},
      params: config.params,
      data: config.body,
      timeout: config.timeout || 5000,
    });

    if (config.transformResponse) {
      response.data = config.transformResponse(response.data);
    }

    return {
      data: response.data,
      status: response.status as HttpStatusCode,
      headers: response.headers,
    } as ApiResponse<T>;
  },
  get<T, B = unknown>(url: string, config = {}) {
    return this.request<T, B>({
      url,
      method: "get",
      ...config,
    });
  },
  async $get<T, B = unknown>(url: string, config: ApiRequestConfig<T, B> = {}) {
    const response = await this.request<T, B>({
      url,
      method: "get",
      ...config,
    });
    return response.data as T;
  },
  post<T, B = unknown>(url: string, config: ApiRequestConfig<T, B> = {}) {
    return this.request<T, B>({ url, method: "post", ...config });
  },
  async $post<T, B = unknown>(
    url: string,
    config: ApiRequestConfig<T, B> = {}
  ) {
    const response = await this.request<T, B>({
      url,
      method: "post",
      ...config,
    });

    return response.data;
  },
  put<T, B = unknown>(url: string, config: ApiRequestConfig<T, B> = {}) {
    return this.request<T, B>({ url, method: "put", ...config });
  },
  async $put<T, B = unknown>(url: string, config: ApiRequestConfig<T, B> = {}) {
    const response = await this.request<T, B>({
      url,
      method: "put",
      ...config,
    });
    return response.data;
  },
  patch<T, B = unknown>(url: string, config: ApiRequestConfig<T, B> = {}) {
    return this.request<T, B>({ url, method: "patch", ...config });
  },
  async $patch<T, B = unknown>(
    url: string,
    config: ApiRequestConfig<T, B> = {}
  ) {
    const response = await this.request<T, B>({
      url,
      method: "patch",
      ...config,
    });
    return response.data;
  },
  delete<T, B = unknown>(url: string, config: ApiRequestConfig<T, B> = {}) {
    return this.request<T, B>({ url, method: "delete", ...config });
  },
  async $delete<T, B = unknown>(
    url: string,
    config: ApiRequestConfig<T, B> = {}
  ) {
    const response = await this.request<T, B>({
      url,
      method: "delete",
      ...config,
    });
    return response.data;
  },
};
