import type { RouteRecordRaw } from "vue-router";

import { ROUTE_BASE_NAME, ROUTE_NAMES } from "./config";

export const routes: RouteRecordRaw[] = [
  {
    name: ROUTE_BASE_NAME,
    path: "/auth",
    component: () => import("./AuthRoot.vue"),
    redirect: {
      name: ROUTE_NAMES.Login,
    },
    children: [
      {
        path: "login",
        name: ROUTE_NAMES.Login,
        component: () => import("./views/login/LoginPage.vue"),
      },
      {
        path: "auto-login/:token",
        name: ROUTE_NAMES.AutoLogin,
        component: () => import("./views/login/AutoLogin.vue"),
      },
      {
        path: "sign-up",
        name: ROUTE_NAMES.SignUp,
        component: () => import("./views/sign-up/SignUp.vue"),
        redirect: {
          name: ROUTE_NAMES.SignUpStep1,
        },
        children: [
          {
            path: "step-1",
            name: ROUTE_NAMES.SignUpStep1,
            component: () => import("./views/sign-up/step-1.vue"),
          },
          {
            path: "step-2",
            name: ROUTE_NAMES.SignUpStep2,
            component: () => import("./views/sign-up/step-2.vue"),
          },
          {
            path: "step-3",
            name: ROUTE_NAMES.SignUpStep3,
            component: () => import("./views/sign-up/step-3.vue"),
          },
        ],
      },
      {
        path: "forgot-password",
        name: ROUTE_NAMES.ForgotPassword,
        component: () => import("./views/forgot-password/ForgotPage.vue"),
      },
    ],
  },
];
