export const ROUTE_BASE_NAME = "auditor";

export const ROUTE_NAMES = {
  Update: `${ROUTE_BASE_NAME}.update`,
  ChangePassword: `${ROUTE_BASE_NAME}.change-password`,
  Main: `${ROUTE_BASE_NAME}.main`,
  Assistants: `${ROUTE_BASE_NAME}.users`,
  Companies: `${ROUTE_BASE_NAME}.companies`,
  CreateUser: `${ROUTE_BASE_NAME}.user.create`,
};
